import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ImageGallery from '../components/imageGallery';

const Galeria = () => {
  return (
    <Layout>
      <SEO title="Galeria" />
      <ImageGallery />
    </Layout>
  );
};

export default Galeria;
