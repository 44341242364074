import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './imageGallery.module.scss';
import { OfferThumbnail } from './offerThumbnail';
import Lightbox from 'react-image-lightbox';

const ImageGallery = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const data = useStaticQuery(graphql`
    {
      allImagesJson {
        edges {
          node {
            id
            image {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
              thumbnailImgs: childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);
  const images = data.allImagesJson.edges;

  return (
    <div className={styles.container}>
      {images.map(({ node: img }, index) => (
        <div
          key={img.id}
          className={styles.thumbnailHolder}
          onClick={() => {
            setPhotoIndex(index);
            setIsOpen(true);
          }}>
          <OfferThumbnail fluid={img.image.thumbnailImgs.fluid} />
        </div>
      ))}
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex].node.image.childImageSharp.fluid.src}
          nextSrc={images[(photoIndex + 1) % images.length].node.image.childImageSharp.fluid.src}
          prevSrc={images[(photoIndex + images.length - 1) % images.length].node.image.childImageSharp.fluid.src}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </div>
  );
};

export default ImageGallery;
