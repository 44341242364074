import React from 'react';
import Img from 'gatsby-image';
import styles from './offerThumbnail.module.scss';

export const OfferThumbnail = ({ fluid }) => {
  return (
    <div className={styles.container}>
      <Img fluid={fluid} />
    </div>
  );
};
